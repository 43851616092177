import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Typography } from 'antd'
import { Line } from 'react-chartjs-2'

import { useTheme } from 'providers'
import { MachineMetricsDataType } from 'types/enums'

import type * as Store from 'types/store'

import { getChartData, getChartOptions } from './chartHelper'
import LegendComponent from './Legend'
import Summary from './Summary'

import styles from './styles.module.scss'

// TODO: finish typing of props
type Props =
  | {
      type: MachineMetricsDataType.Voltage
      metrics: Store.MachineLatestVoltageData
    }
  | {
      type: MachineMetricsDataType.Current
      metrics: Store.MachineLatestCurrentData
    }
  | {
      type: MachineMetricsDataType.Power
      metrics: Store.MachineLatestPowerData
    }
  | {
      type: MachineMetricsDataType.PowerFactor
      metrics: Store.MachineLatestPowerFactorData
    }
  | {
      type: MachineMetricsDataType.Vthd | MachineMetricsDataType.Ithd
      metrics: Store.MachineLatestThdData
    }

const containerStyle: React.CSSProperties = { width: '100%', height: '350px' }

export const RealtimeChartBox: React.FC<Props> = (props) => {
  const { theme } = useTheme()
  const { t } = useTranslation()

  const [chartData, setChartData] = useState(() => getChartData(theme, props, t))
  const options = useMemo(() => getChartOptions(theme), [theme])

  useEffect(() => {
    const updatedChartData = getChartData(theme, props, t)
    setChartData(updatedChartData)
  }, [theme, props, t, setChartData])

  const getChartTitle = () => {
    const getTitleText = (title, unit?) => {
      return (
        <Typography.Title level={4}>
          {title}
          {unit && <span className="unit">{`[${unit}]`}</span>}
        </Typography.Title>
      )
    }

    switch (props.type) {
      case MachineMetricsDataType.Voltage:
        return getTitleText(t('machineDetail.voltage'), t('metricUnit.voltage'))
      case MachineMetricsDataType.Current:
        return getTitleText(t('machineDetail.current'), t('metricUnit.current'))
      case MachineMetricsDataType.Power:
        return getTitleText(t('machineDetail.totalPower'), t('metricUnit.power'))
      case MachineMetricsDataType.PowerFactor:
        return getTitleText(t('machineDetail.powerFactor'))
      case MachineMetricsDataType.Vthd:
        return getTitleText(t('machineDetail.vthd'), t('metricUnit.percentage'))
      case MachineMetricsDataType.Ithd:
        return getTitleText(t('machineDetail.ithd'), t('metricUnit.percentage'))
    }
  }

  const handleLegendClick = useCallback(
    (index: number) => {
      setChartData((chartData) => {
        const newDatasets = [...chartData.datasets]
        newDatasets[index] = { ...newDatasets[index], hidden: !newDatasets[index].hidden }
        return { ...chartData, datasets: newDatasets }
      })
    },
    [setChartData],
  )

  const summary = useMemo<React.ReactNode>(
    () => (
      <>
        {getChartTitle()}

        {props.type === MachineMetricsDataType.Voltage && (
          <Summary
            data={[
              {
                description: t('machineDetail.voltageBox.title.inMax'),
                unit: 'V',
                value: props.metrics.summary.vMax,
              },
              {
                description: t('machineDetail.voltageBox.title.inMin'),
                unit: 'V',
                value: props.metrics.summary.vMin,
              },
              {
                description: t('machineDetail.voltageBox.title.outAvg'),
                unit: 'V',
                value: props.metrics.summary.vOut,
              },
            ]}
          />
        )}
      </>
    ),
    [props.type, props.metrics, t],
  )

  return (
    <Card className={styles['chart-box']} bordered={false}>
      {summary}
      <LegendComponent datasets={chartData.datasets} onLegendClick={handleLegendClick} />

      <div style={containerStyle}>
        <Line data={chartData} options={options} />
      </div>
    </Card>
  )
}

export default RealtimeChartBox
